$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.apple-pay-btn__from-pdp {
  margin-top: $spacer-xs;
  width: 100%;
}

.waitlist-form__policy-text {
  @include font($body-small);

  color: $grey-400;
  margin-bottom: $spacer-s;
  margin-top: $spacer-xs;

  a {
    color: $grey-400;
  }
}

.waitlist-form__input {
  @include input-reset;
  @include font($body-medium);

  background-color: $grey-100;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  line-height: 22px;
  margin: 10px 0;
  padding: $spacer-s;
  width: 100%;
}

.product-cta {
  @include font($body-medium);

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacer-s $spacer-s $spacer-xs;

  @include breakpoint(tablet) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include breakpoint(desktop) {
    padding-left: 0;
    padding-right: 0;
  }

  &--sticky {
    @include z-index(product-cta);

    align-items: center;
    background-color: $white;
    bottom: 0;
    box-shadow: 0 0 6px 0 $grey-300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: 20px 0;
    position: fixed;
    width: 100%;
  }

  &__promo-code-badge--token {
    background-color: $red-100;
    color: $red-500;
    letter-spacing: 0.08rem;
    margin-left: $spacer-xxs;
    padding: $spacer-xxs 12px;
    text-transform: uppercase;
  }

  &__button-container,
  &--sticky {
    &__split-button {
      flex-grow: 1;

      &:last-of-type {
        margin-left: $spacer-xs;
      }
    }

    &__split-button-link {
      text-decoration: none;
    }
  }

  &__final-sale {
    @include font($body-medium);

    color: $red-500;
    margin-top: $spacer-xs;
    text-align: center;
  }

  &__domestic-only {
    @include font($body-medium);

    color: $green-500;
    margin-top: $spacer-xxs;
  }

  &__icon {
    height: 13px;
  }

  &__button {
    &--sticky {
      width: 90%;
    }

    &--success, &--success:disabled {
      background-color: $green-600;
      color: $white;

      svg {
        float: right;
      }
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    width: 100%;

    &--has-children {
      width: 50%;
    }

    &--minimal {
      width: 100px;
    }
  }

  &__stripe-apple-pay-wrapper {
    margin: $spacer-s 0;
  }

  &__giftcard-helper {
    @include font($body-small);

    color: $grey-500;
    margin: $spacer-s auto;
  }

}

@media (hover: hover) and (pointer: fine) {
  .product-cta__button--disabled {
    &:hover {
      cursor: initial;
    }
  }
}

.giftcard-modal {
  padding: 40px 20px 20px;

  @include breakpoint(tablet) {
    border-radius: 0 !important;
    height: fit-content;
    min-height: 500px;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%) !important;
    width: 628px !important;
  }

  &__title {
    @include font($heading-medium);

    margin-bottom: 10px;
    text-align: center;
  }

  &__form {
    overflow: hidden;
  }

  &__button {
    margin: 20px 0;

    @include breakpoint(tablet) {
      margin-top: $spacer-m;
    }
  }

  &__field {
    display: block;
    margin-top: $spacer-s;
    position: relative;
    width: 100%;
  }

  &__label {
    @include font($body-small);

    color: $grey-400;
    display: flex;
    width: 100%;
  }

  &__input {
    @include font($body-medium);

    background: $grey-100;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: $black;
    line-height: 22px;
    min-width: 100%;
    outline: none;
    padding: $spacer-s;

    &::placeholder {
      color: $grey-500;
    }
  }

  &__input--date {
    cursor: pointer;
    height: 55px;

    // Fix For FF
    @-moz-document url-prefix() {
      padding: 26px 14px 10px !important;
    }

    &-caret {
      bottom: $spacer-s;
      cursor: pointer;
      position: absolute;
      right: $spacer-s;
      transform: scale(1.2) rotate(180deg) translateY(25%);
    }

    &-calendar {
      bottom: 50%;
      cursor: pointer;
      left: $spacer-s;
      position: absolute;
      transform: scale(1.2) translateY(40%);

      &.has-value {
        display: none;
      }
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: 24px;
      left: 0;
      margin: 0;
      padding: 13px 0 13px $spacer-s;
      position: absolute;
      width: 97%;
    }

    &.has-value {
      padding: 26px 14px 10px !important;

      &::before {
        @include font($body-small);

        color: $grey-400;
        content: attr(placeholder);
        left: 15px;
        position: absolute;
        top: 8px;
        transform: translateY(0);
        z-index: 1;
      }

      &::-webkit-datetime-edit {
        position: relative;
        top: 2px;
      }
    }

    // eslint-disable-next-line no-invalid-double-slash-comments
    // setting position to shadow element after makes it clickable
    &:not(.has-value)::after {
      color: $grey-500;
      content: attr(placeholder);
      line-height: 100%;
      padding: 2px 0 0 $spacer-m;
      pointer-events: none;
      width: 100%;
    }

    &::-webkit-date-and-time-value {
      margin-left: $spacer-m;
      text-align: left;
    }

    &:not(.has-value)::-webkit-datetime-edit-text,
    &:not(.has-value)::-webkit-datetime-edit-month-field,
    &:not(.has-value)::-webkit-datetime-edit-fields-wrapper,
    &:not(.has-value)::-webkit-datetime-edit-day-field,
    &:not(.has-value)::-webkit-datetime-edit-year-field {
      -webkit-appearance: none;
      display: none;
    }
  }

  // Prevent overlap that cannot be removed in FF's datetime-local input
  @-moz-document url-prefix() {
    &__input--date-caret,
    &__input--date-calendar {
      display: none;
    }
  }

  // Safari fix for unaligned input value
  & input::-webkit-date-and-time-value {
    margin: 0;
    margin-top: $spacer-s;
    padding: $spacer-xs;
    text-align: left;
  }

  &__date-input-label {
    @include font($body-medium);

    color: $grey-500;
    left: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    // Fix For FF
    @-moz-document url-prefix() {
      @include font($body-small);

      color: $grey-400;
      left: 15px;
      top: 8px;
      transform: translateY(0);
    }

    &.has-value {
      @include font($body-small);

      color: $grey-400;
      left: 15px;
      top: 8px;
      transform: translateY(0);
    }
  }

  &__error {
    @include font($body-small);

    color: $red-600;
    padding-left: 5px;
  }
}
